//import io from 'socket.io-client'

const io = require("socket.io-client");

//HEROKU
//const URL = 'https://auth-mini-api.herokuapp.com/
//VERCEL
//const URL = 'https://constelaciones-backend.vercel.app/'
//RAILWAY
//const URL = 'https://web-production-79a4.up.railway.app/'
//RENDER
//const URL = 'https://constelaciones-backend-render.onrender.com/'
const URL =  process.env.REACT_APP_SOCKETS_URL

const socket = io(URL)


let mySocketId = null

socket.on("createNewSesion", statusUpdate => {
    console.log("(socket.js) Se ha creado una NUEVA sesion! ID Sesion: " +
        statusUpdate.sesionId + " Socket id: " + statusUpdate.mySocketId)
    mySocketId = statusUpdate.mySocketId
})

export {
    socket,
    mySocketId
}
