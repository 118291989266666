export default {
    'h00' :['https://claudiapedrosa.com/fgs/H00.png'],
    'h01' :['https://claudiapedrosa.com/fgs/H01.png'],
    'h02' :['https://claudiapedrosa.com/fgs/H02.png'],
    'h03' :['https://claudiapedrosa.com/fgs/H03.png'],
    'h04' :['https://claudiapedrosa.com/fgs/H04.png'],
    'h05' :['https://claudiapedrosa.com/fgs/H05.png'],
    'h06' :['https://claudiapedrosa.com/fgs/H06.png'],
    'h07' :['https://claudiapedrosa.com/fgs/H07.png'],
    'h08' :['https://claudiapedrosa.com/fgs/H08.png'],
    'h09' :['https://claudiapedrosa.com/fgs/H09.png'],
    'h10' :['https://claudiapedrosa.com/fgs/H10.png'],
    'h11' :['https://claudiapedrosa.com/fgs/H11.png'],
    'h12' :['https://claudiapedrosa.com/fgs/H12.png'],
    'h13' :['https://claudiapedrosa.com/fgs/H13.png'],
    'h14' :['https://claudiapedrosa.com/fgs/H14.png'],
    'h15' :['https://claudiapedrosa.com/fgs/H15.png'],
    'm00' :['https://claudiapedrosa.com/fgs/M00.png'],
    'm01' :['https://claudiapedrosa.com/fgs/M01.png'],
    'm02' :['https://claudiapedrosa.com/fgs/M02.png'],
    'm03' :['https://claudiapedrosa.com/fgs/M03.png'],
    'm04' :['https://claudiapedrosa.com/fgs/M04.png'],
    'm05' :['https://claudiapedrosa.com/fgs/M05.png'],
    'm06' :['https://claudiapedrosa.com/fgs/M06.png'],
    'm07' :['https://claudiapedrosa.com/fgs/M07.png'],
    'm08' :['https://claudiapedrosa.com/fgs/M08.png'],
    'm09' :['https://claudiapedrosa.com/fgs/M09.png'],
    'm10' :['https://claudiapedrosa.com/fgs/M10.png'],
    'm11' :['https://claudiapedrosa.com/fgs/M11.png'],
    'm12' :['https://claudiapedrosa.com/fgs/M12.png'],
    'm13' :['https://claudiapedrosa.com/fgs/M13.png'],
    'm14' :['https://claudiapedrosa.com/fgs/M14.png'],
    'm15' :['https://claudiapedrosa.com/fgs/M15.png']                             
}